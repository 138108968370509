export default {
  'tariff.router.name': 'Storage Subscription',
  'tariff.router.nameBreadcrumb': 'Subscription Management',
  'tariff.optionsFilter.TariffManagement': 'Subscription List',
  'tariff.optionsFilter.TariffRenewalHistory': 'Transaction History',
  'tariff.router.TariffManagement': 'Subscription List',
  'tariff.router.TariffRenewalHistory': 'Transaction History',
  'tariff.TariffManagement.title': 'Subscription List',
  'tariff.TariffManagement.table.capacity': 'Storage Capacity',
  'tariff.TariffManagement.table.name': 'Subscription Name',
  'tariff.TariffManagement.table.periodByMonth': 'Validity',
  'tariff.TariffManagement.table.price': 'Subscription Fee',
  'tariff.TariffManagement.table.accessibility': 'Publish Subscription',
  'tariff.TariffManagement.table.numberOfPurchases': 'Number of Subscription',
  'tariff.TariffManagement.table.createdAt': 'Created Time',
  'tariff.TariffManagement.table.businessStatusExtend': 'Status',
  'tariff.TariffManagement.table.businessStatus.param':
    '{status,select, 1 {In Active} 2 {Stopped} 3 {Upcoming} 4 {Finished} other {Undefined}}',
  'tariff.TariffManagement.deleteTitle': 'Delete Subscription Confirmation',
  'tariff.TariffManagement.deleteContent':
    'This subscription will be deleted and can not be recovered',
  'tariff.TariffManagement.filter.businessStatus': 'Status',
  'tariff.TariffManagement.filter.businessStatus.inBusiness': 'In Active',
  'tariff.TariffManagement.filter.businessStatus.outOfBusiness': 'Stopped',
  'tariff.TariffManagement.filter.periodByMonth': 'Validity',
  'tariff.TariffManagement.router.add.name': 'Add Subscription',
  'tariff.TariffManagement.router.update.name': 'Update Subscription',
  'tariff.TariffManagement.update.title': 'Update Subscription',
  'tariff.TariffManagement.add.title': 'Add Subscription',
  'tariff.TariffManagement.form.capacity': 'Storage Capacity',
  'tariff.TariffManagement.form.capacity.msg': 'Capacity must be a multiple of 0.5',
  'tariff.TariffManagement.form.code': 'Subscription Code',
  'tariff.TariffManagement.form.name': 'Subscription Name',
  'tariff.TariffManagement.form.tariffName': 'Capacity',
  'tariff.TariffManagement.form.periodByMonth': 'Validity',
  'tariff.TariffManagement.form.periodByMonth.blank': 'Please select a validity',
  'tariff.TariffManagement.form.periodByMonth.msg': 'Duplicate Validity',
  'tariff.TariffManagement.form.price': 'Subscription Fee',
  'tariff.TariffManagement.form.businessStatus': 'Status',
  'tariff.TariffManagement.form.accessibility.public': 'Publish Subscription',
  'tariff.TariffManagement.form.accessibility.imited': 'Registration Validity',
  'tariff.TariffManagement.form.accessibility': 'Publish Subscription',
  'tariff.TariffManagement.form.applicableTime.msg': 'Please select a time',
  'tariff.TariffManagement.form.numberOfPurchases': 'Number of Subscribers',
  'tariff.TariffManagement.form.createdAt': 'Created Time',
  'tariff.TariffManagement.form.btn.add': 'Add another Subscription',
  'tariff.TariffManagement.form.businessStatus.inBusiness': 'In Active',
  'tariff.TariffManagement.form.businessStatus.outOfBusiness': 'Stopped',
  'tariff.TariffManagement.info.businessStatus.inBusiness': 'In Active',
  'tariff.TariffManagement.info.businessStatus.outOfBusiness': 'Stopped',
  'tariff.TariffManagement.info.title': 'Subscription Information',
  'tariff.TariffManagement.info.router.title': 'Subscription detail',
  'tariff.TariffManagement.info.capacity': 'Storage Capacity',
  'tariff.TariffManagement.info.code': 'Subscription Code',
  'tariff.TariffManagement.info.name': 'Subscription Name',
  'tariff.TariffManagement.info.periodByMonth': 'Validity',
  'tariff.TariffManagement.info.price': 'Subscription Fee',
  'tariff.TariffManagement.info.businessStatus': 'Status',
  'tariff.TariffManagement.info.createdAt': 'Created Time',
  'tariff.TariffManagement.info.creator': 'Creator',
  'tariff.TariffManagement.info.accessibility': 'Publish Subscription',
  'tariff.TariffManagement.info.numberOfPurchases': 'Number of Subscription',
  'tariff.TariffManagement.info.time': 'Validity Period',
  'tariff.TariffManagement.info.error': 'Your subscription is stopped.',
  'tariff.TariffManagement.info.tariffName': 'Capacity',
  'tariff.TariffRenewalHistory.table.accessibility.public.unlimited':
    'Unlimited Registration Validity',
  'tariff.TariffRenewalHistory.table.accessibility.private': 'Not Public',
  'tariff.TariffRenewalHistory.summary.totalTariffPackage': 'Total Subscription',
  'tariff.TariffRenewalHistory.summary.totalRevenue': 'Total Revenue',
  'tariff.TariffRenewalHistory.title': 'Transaction History',
  'tariff.TariffRenewalHistory.table.organizationCode': 'Account ID',
  'tariff.TariffRenewalHistory.table.organization': 'Account',
  'tariff.TariffRenewalHistory.table.organizationName': 'Account Name',
  'tariff.TariffRenewalHistory.table.gracetime': 'Renewal Time',
  'tariff.TariffRenewalHistory.table.capacity': 'Capacity',
  'tariff.TariffRenewalHistory.table.periodByMonth': 'Duration',
  'tariff.TariffRenewalHistory.table.EffectiveTime': 'Valid From',
  'tariff.TariffRenewalHistory.table.price': 'Subscription Fee',
  'tariff.TariffRenewalHistory.table.payerName': 'Payer Information',
  'tariff.TariffRenewalHistory.table.paymentMethod': 'Payment Method',
  'tariff.TariffRenewalHistory.table.paymentStatus': 'Payment Status',
  'tariff.TariffRenewalHistory.table.paymentStatus.param':
    '{status,select, 1 {Succeeded} 2 {Processing} 3 {Failed} 4 {Canceled} other {Other}}',
  'tariff.TariffRenewalHistory.filter.isLimited.no': 'Unlimited',
  'tariff.TariffRenewalHistory.filter.organization': 'Account',
  'tariff.TariffRenewalHistory.filter.periodByMonth': 'Validity',
  'tariff.TariffRenewalHistory.filter.paymentStatus': 'Payment Status',
  'tariff.TariffRenewalHistory.filter.gracetime': 'Renewal Time',
  'tariff.TariffRenewalHistory.filter.paymentStatus.success': 'Successful',
  'tariff.TariffRenewalHistory.filter.paymentStatus.processing': 'Processing',
  'tariff.TariffRenewalHistory.filter.paymentStatus.failure': 'Failed',
};
