import React from 'react';

import { PermissionEnum } from '@modules/permissions/entity';
import { IRouter } from '@routers/interface';

export const routerFacilityPlaylistAdd: IRouter = {
  path: '/facility/:facilityId/facility-playlist/add',
  loader: React.lazy(() => import('./Pages/FormPlaylist')),
  exact: true,
  name: 'facility.router.playlist.create.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesPlaylistsCreate,
};

export const routerFacilityPlaylistUpdate: IRouter = {
  path: '/facility/:facilityId/facility-playlist/update/:playlistId',
  loader: React.lazy(() => import('./Pages/FormPlaylist')),
  exact: true,
  name: 'facility.router.playlist.update.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesPlaylistsUpdate,
};

export const routerFacilityPlaylistInfo: IRouter = {
  path: '/facility/:facilityId/facility-playlist/info/:playlistId',
  loader: React.lazy(() => import('./Pages/PlaylistInfo')),
  exact: true,
  name: 'facility.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesPlaylistsViewDetail,
  generatePath(playlistId: string, facilityId: string, listPermissions: string[]) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/facility/${facilityId}/facility-playlist/info/${playlistId}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}`;
    }
    return `/facility/${facilityId}/facility-playlist/info/${playlistId}`;
  },
};

export const routerOrganizationFacilityPlaylistInfo: IRouter = {
  path: '/organization/:organizationId/facility/:facilityId/facility-playlist/info/:playlistId',
  loader: React.lazy(() => import('./Pages/PlaylistInfo')),
  exact: true,
  name: 'facility.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(
    organizationId: string,
    playlistId: string,
    facilityId: string,
    listPermissions: string[],
  ) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/organization/${organizationId}/facility/${facilityId}/facility-playlist/info/${playlistId}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}`;
    }
    return `/organization/${organizationId}/facility/${facilityId}/facility-playlist/info/${playlistId}`;
  },
};
//only for breadcrumbs usage
export const routerFacilityPlaylist: IRouter = {
  path: `/facility/:facilityId?tab=${PermissionEnum.FacilitiesPlaylistsView}`,
  loader: React.lazy(() => import('./Pages/PlaylistInfo')),
  exact: true,
  name: 'facility.router.playlist.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  permissionCode: PermissionEnum.FacilitiesPlaylistsViewDetail,
  generatePath(facilityId, organizationId?) {
    if (organizationId) {
      return `/organizations/${organizationId}/facility/${facilityId}?tab=${PermissionEnum.FacilitiesPlaylistsView}`;
    } else return `/facility/${facilityId}?tab=${PermissionEnum.FacilitiesPlaylistsView}`;
  },
};

export const routerOrganizationFacilityPlaylistAdd: IRouter = {
  path: '/organization/:organizationId/:facilityId/source-information/playlist/add',
  loader: React.lazy(() => import('./Pages/FormPlaylist')),
  exact: true,
  name: 'facility.router.playlist.organization.create.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};

export const routerOrganizationFacilityPlaylistUpdate: IRouter = {
  path: '/organization/:organizationId/:facilityId/source-information/playlist/update/:playlistId',
  loader: React.lazy(() => import('./Pages/FormPlaylist')),
  exact: true,
  name: 'facility.router.playlist.organization.update.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
};
