export default {
  'dashboard.router.manager': 'Dashboard',
  'dashboard.summary.organizations': 'Tổ chức',
  'dashboard.summary.organizations.expired': 'Tổ chức hết hạn',
  'dashboard.summary.organizations.due': 'Tổ chức còn hạn',
  'dashboard.summary.capacityUsed.text': 'Dung lượng tổ chức đã sử dụng',
  'dashboard.summary.capacity.text': 'Dung lượng tổ chức đã đăng ký',
  'dashboard.filter.organizations': 'Tổ chức',
  'dashboard.filter.status': 'Trạng thái hoạt động',
  'dashboard.filter.status.active': 'Đang hoạt động',
  'dashboard.filter.status.notActive': 'Ngưng hoạt động',
  'dashboard.marker.device.name': 'Tên thiết bị',
  'dashboard.marker.organizations': 'Tổ chức',
  'dashboard.marker.specificAddress': 'Địa chỉ',
  'dashboard.marker.latitude': 'Vĩ độ',
  'dashboard.marker.longitude': 'Kinh độ',
  'dashboard.marker.status': 'Trạng thái',
  'dashboard.marker.device.info': 'Thông tin thiết bị',
  'dashboard.marker.devices.operationStatus.id':
    '{status,select, 1 {Đang hoạt động} 2 {Ngưng hoạt động} other {Không xác định}}',
  'dashboard.summary.facilities': 'Cơ sở phát',
  'dashboard.summary.totalDevice': 'Thiết bị phát bản tin',
  'dashboard.summary.totalNewsletter': 'Bản tin',
  'dashboard.summary.capacity': 'Dung lượng',
};
