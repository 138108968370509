export interface Permission {
  permissionCode: string;
  permissionName: string;
  level: number;
  createdAt: string;
}

export enum BusinessStatusEnum {
  InBusiness = 1,
  OutOfBusiness = 2,
}
export enum BusinessStatusExtendEnum {
  InBusiness = 1, // Đang kinh doanh
  OutOfBusiness = 2, // Ngưng kinh doanh
  NotInBusinessYet = 3, // Chưa kinh doanh
  EndOfBusiness = 4, // Đã kết thúc
}
export enum PaymentMethodEnum {
  Direct = 1,
  ElectronicWallet = 2,
}
export enum PaymentStatus {
  Success = 1,
  Processing = 2,
  Failure = 3,
  Cancel = 4,
}
export enum TariffAccessibilityEnum {
  Public = 1,
  Private = 2,
}
export interface Packages {
  code: string;
  id: string;
  name: string;
  periodByMonth: number;
  price: number;
  applicableTimeFrom?: string;
  applicableTimeTo?: string;
  businessStatus: BusinessStatusEnum;
  businessStatusExtend: BusinessStatusExtendEnum;
  accessibility: TariffAccessibilityEnum;
  numberOfPurchases: number;
  createdAt: string;
  creator?: any;
}
