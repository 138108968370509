/* eslint-disable @typescript-eslint/comma-dangle */
import { routerAnnouncements } from '@view/Announcements/router';
import { routerRenewalHistory, routerViewProfile } from '@view/Auth/Profiles/router';
import { routerDashboard, routerDashboardOrganizations } from '@view/Dashboard/router';
import { routerDevicesInfo, routerOrganizationDeviceInfo } from '@view/Devices/Pages/router';
import { routerDevices, routerOrganizationDevice } from '@view/Devices/router';
import {
  routerFacilityDevicesInfo,
  routerOrganizationFacilityDevicesInfo,
} from '@view/Facility/Pages/Device/Pages/router';
import { routerFacilitiesDevices } from '@view/Facility/Pages/Device/router';
import {
  routerMediaFacilityInfo,
  routerOrganizationMediaFacilityInfo,
} from '@view/Facility/Pages/Media/PageInfo/router';
import {
  routerFacilityPlaylist,
  routerFacilityPlaylistAdd,
  routerFacilityPlaylistInfo,
  routerFacilityPlaylistUpdate,
  routerOrganizationFacilityPlaylistAdd,
  routerOrganizationFacilityPlaylistInfo,
  routerOrganizationFacilityPlaylistUpdate,
} from '@view/Facility/Pages/Playlist/Pages/router';
import { routerTemplateFacility } from '@view/Facility/Pages/Template/router';
import {
  routerOrganizationFacilityEditInfo,
  routerOrganizationTemplateFacilityAdd,
  routerTemplateFacilityAdd,
  routerTemplateFacilityEditInfo,
} from '@view/Facility/Pages/Template/TemplateForm/router';
import { routerFacilitiesUsers } from '@view/Facility/Pages/User/router';
import { routerFacility, routerFacilitySystem } from '@view/Facility/router';
import { routerHome } from '@view/Home/router';
import {
  routerNewsDetail,
  routerNewsDetailOrganizations,
  routerNewsManagement,
  routerNewsManagementAdd,
  routerNewsManagementEdit,
  routerNewsManagementOrganizations,
  routerNewsOrganizations,
  routerOrganizationNewsManagementAdd,
  routerOrganizationNewsManagementEdit,
  routerSubNewsDetail,
  routerSubNewsDetailOrganizations,
  routerSubNewsOrganizations,
} from '@view/NewsManagement/router';
import { routerOrganizationsInfoEdit } from '@view/Organizations/Pages/PageInfoAndEdit/router';
import { routerOrganizations } from '@view/Organizations/router';
import { routerPage403 } from '@view/Page403/router';
import { routerPageError } from '@view/PageError/router';
import { routerPagePagePaymentSuccess } from '@view/PagePaymentSuccess/router';
import { routerPlansPage } from '@view/PlansPage/router';
import { routerFields, routerOrganizationFields } from '@view/SettingEndUser/Fields/router';
import {
  routerOrganizationOrganizationSetting,
  routerOrganizationSetting,
} from '@view/SettingEndUser/OrganizationSetting/router';
import { routerSettingEndUser } from '@view/SettingEndUser/router';
import {
  routerOrganizationSuppliers,
  routerSuppliers,
} from '@view/SettingEndUser/Suppliers/router';
import { routerOrganizationUserLogs, routerUserLogs } from '@view/SettingEndUser/UserLogs/router';
import { routerOrganizationSettingSystem, routerSettingSystem } from '@view/SettingSystem/router';
import {
  routerFormRolesAdd,
  routerFormRolesEdit,
  routerOrganizationFormRolesAdd,
  routerOrganizationFormRolesEdit,
} from '@view/SettingSystem/Users/component/FormRoles/router';
import { routerOrganizationRole, routerRole } from '@view/SettingSystem/Users/router';
import {
  routerMediaInfo,
  routerMediaInfoFacility,
  routerOrganizationMediaInfo,
  routerOrganizationMediaInfoFacility,
} from '@view/SourceInformation/Pages/Media/PageInfo/router';
import {
  routerOrganizationPlaylistAdd,
  routerOrganizationPlaylistUpdate,
  routerPlaylistAdd,
  routerPlaylistUpdate,
} from '@view/SourceInformation/Pages/Playlist/Pages/FormPlaylist/router';
import {
  routerOrganizationPlaylistInfo,
  routerOrganizationPlaylistInfoFacility,
  routerPlaylistInfo,
  routerPlaylistInfoFacility,
} from '@view/SourceInformation/Pages/Playlist/Pages/PlaylistInfo/router';
import { routerOrganizationNewsletter } from '@view/SourceInformation/Pages/Template/PagesInfo/Newsletter/router';
import {
  routerOrganizationTemplateAdd,
  routerOrganizationTemplateEditInfo,
  routerOrganizationTemplateEditInfoFacility,
  routerTemplateAdd,
  routerTemplateEditInfo,
  routerTemplateEditInfoFacility,
} from '@view/SourceInformation/Pages/Template/TemplateForm/router';
import {
  routerOrganizationSourceInformation,
  routerSourceInformation,
} from '@view/SourceInformation/router';
import { routerFormTariffAdd, routerFormTariffUpdate } from '@view/Tariff/Pages/FormTariff/router';
import { routerTariffInfo } from '@view/Tariff/Pages/TariffInfo/router';
import { routerTariff } from '@view/Tariff/router';

import { IRouter } from './interface';

export const endUserPages: IRouter[] = [
  routerHome,
  routerDashboard,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerPlansPage,
  routerSubNewsDetail,
  routerFacility,
  routerSourceInformation,
  routerFacilityPlaylist,
  routerFacilityPlaylistAdd,
  routerFacilityPlaylistUpdate,
  routerFacilityPlaylistInfo,
  routerFacilityDevicesInfo,
  routerNewsManagement,
  routerNewsManagementAdd,
  routerNewsManagementEdit,
  routerNewsDetail,
  routerTemplateFacility,
  routerTemplateFacilityAdd,
  routerTemplateFacilityEditInfo,
  routerMediaFacilityInfo,
  routerTemplateAdd,
  routerTemplateEditInfo,
  routerDevices,
  routerDevicesInfo,
  routerMediaInfo,
  routerFields,
  routerSuppliers,
  routerMediaFacilityInfo,
  routerFields,
  routerFormRolesAdd,
  routerRole,
  routerFacilitiesDevices,
  routerFacilitiesUsers,
  routerPlaylistInfo,
  routerPlaylistAdd,
  routerPlaylistUpdate,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerSettingEndUser,
  routerUserLogs,
  routerOrganizationSetting,
  routerViewProfile,
  routerFormRolesAdd,
  routerFormRolesEdit,
  routerRenewalHistory,
  routerTemplateEditInfoFacility,
  routerMediaInfoFacility,
  routerPlaylistInfoFacility,
  routerPagePagePaymentSuccess,
  routerPage403,
  routerPageError,
];

export const systemUserPages: IRouter[] = [
  routerHome,
  routerDashboard,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerOrganizations,
  routerFacilityPlaylist,
  routerFacilityPlaylistAdd,
  routerFacilityPlaylistUpdate,
  routerFacilityPlaylistInfo,
  routerOrganizationsInfoEdit,
  routerMediaFacilityInfo,
  routerTemplateFacilityAdd,
  routerTemplateFacilityEditInfo,
  routerAnnouncements,
  routerTariff,
  routerTariffInfo,
  routerFormTariffAdd,
  routerFormTariffUpdate,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerSettingSystem,
  routerRole,
  routerFormRolesAdd,
  routerFormRolesEdit,
  routerViewProfile,
  routerPlansPage,

  routerPage403,
  routerPageError,
];

export const organizationsPage: IRouter[] = [
  routerHome,
  routerDashboardOrganizations,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerFacilitySystem,
  routerPlansPage,

  routerOrganizationSourceInformation,
  routerOrganizationMediaInfo,
  routerOrganizationTemplateAdd,
  routerOrganizationTemplateEditInfo,
  routerOrganizationNewsletter,
  routerOrganizationPlaylistInfo,
  routerOrganizationPlaylistAdd,
  routerOrganizationPlaylistUpdate,
  routerNewsManagementOrganizations,
  routerOrganizationDevice,
  routerOrganizationDeviceInfo,
  routerOrganizationOrganizationSetting,
  routerOrganizationNewsManagementAdd,
  routerOrganizationNewsManagementEdit,
  routerFacilityDevicesInfo,
  routerOrganizationMediaFacilityInfo,
  routerOrganizationFacilityDevicesInfo,
  routerOrganizationFacilityEditInfo,
  routerOrganizationFacilityPlaylistInfo,
  {
    path: null,
    name: '',
    masterLayout: true,
    type: 'divider',
    menu: {},
  },
  routerOrganizationSettingSystem,
  routerOrganizationRole,
  routerOrganizationFormRolesEdit,
  routerOrganizationFormRolesAdd,
  routerOrganizationSuppliers,
  routerOrganizationFields,
  routerOrganizationUserLogs,
  routerNewsDetailOrganizations,
  routerSubNewsDetailOrganizations,
  routerNewsOrganizations,
  routerSubNewsOrganizations,
  routerOrganizationFacilityPlaylistAdd,
  routerOrganizationFacilityPlaylistUpdate,
  routerOrganizationTemplateFacilityAdd,
  routerOrganizationTemplateEditInfoFacility,
  routerOrganizationMediaInfoFacility,
  routerOrganizationPlaylistInfoFacility,
];

export const publicPage: IRouter[] = [routerPlansPage, routerPageError];
