import { PaginationEntity } from '@core/pagination/entity';
import httpRepository from '@core/repository/http';
import { OptionEntity, TableEntity } from '@core/table';

import NewsEntity from './newsEntity';

// API GET
export const getListNews = (
  pagination: PaginationEntity,
  options: OptionEntity,
  organizationId?: string,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path:
      organizationId != null ? `/api/Facilities/${organizationId}/Newsletters` : '/api/Newsletters',
    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: NewsEntity.createListNews(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};

export const getListSubNews = (
  pagination: PaginationEntity,
  options: OptionEntity,
  organizationId?: string,
) => {
  const params = new TableEntity(pagination, options);
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/SubunitNewsletters`
        : '/api/SubunitNewsletters',

    showSuccess: false,
    showError: false,
    params,
    convert: res => {
      return {
        data: NewsEntity.createListNews(res?.pagedData),
        info: new PaginationEntity(res?.pageInfo),
      };
    },
  });
};

//and get detail
export const getDetailNews = (id: string, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/${id}`
        : `/api/Newsletters/${id}`,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new NewsEntity(res);
    },
  });
};

export const getDetailSubNews = (id: string, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/SubunitNewsletters/${id}`
        : `/api/SubunitNewsletters/${id}`,
    showSuccess: false,
    showError: false,
    convert: res => {
      return new NewsEntity(res);
    },
  });
};

//API ADD
export const createNews = (payload: any) => {
  return httpRepository.execute({
    path: '/api/news',
    method: 'post',
    payload,
  });
};

//API EDIT/UPDATE
export const updateNews = (id: string, payload: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/` + id
        : '/api/Newsletters/' + id,
    method: 'put',
    payload,
  });
};

//API DELETE
export const deleteNews = (ids: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/DeleteMany`
        : '/api/Newsletters/DeleteMany',
    method: 'post',
    payload: ids,
  });
};

export const approveNews = (ids: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/ApproveMany`
        : '/api/Newsletters/ApproveMany',
    method: 'post',
    payload: ids,
  });
};

export const refuseApproveNews = (id?: string, payload?: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/${id}/RefuseApproval`
        : `/api/Newsletters/${id}/RefuseApproval`,
    method: 'post',
    payload,
  });
};

export const cancelNews = (ids: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/CancelMany`
        : '/api/Newsletters/CancelMany',
    method: 'post',
    payload: ids,
  });
};

export const playNews = (id?: string, payload?: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/${id}/PlaybackControls`
        : `/api/Newsletters/${id}/PlaybackControls`,
    method: 'put',
    payload,
  });
};

//API ADD NEWLETTERS
export const createNewsletters = (payload: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null ? `/api/Facilities/${organizationId}/Newsletters` : '/api/Newsletters',
    method: 'post',
    payload,
  });
};

//API INSPECT CONFLICT
export const inspectConflict = (payload: any, organizationId?: string) => {
  return httpRepository.execute({
    path:
      organizationId != null
        ? `/api/Facilities/${organizationId}/Newsletters/InspectConflict`
        : '/api/Newsletters/InspectConflict',
    method: 'post',
    payload,
    showSuccess: false,
  });
};
