import dayjs from 'dayjs';

export enum SeenStatus {
  SEEN = 1,
  UNSEEN = 2,
}

export enum GroupNotify {
  ORGANIZATION = 1,
  INTERNAL = 2,
}

export enum TextStyle {
  TEXT = 1,
  TEMPLATE = 2,
}

export enum eventType {
  ApproveMedia = 1,
  RefuseMediaApproval = 2,
  ShareMedia = 3,
  ReceptionMedia = 4,
  ApproveNewsletter = 5,
  RefuseNewsletterApproval = 6,
  ScheduleConflict = 7,
  TariffPackageExpiringSoon = 8,
  DataAlmostFull = 9,
  MEDIA_NEED_APPROVE = 10,
  NEWSLETTER_NEED_APPROVE = 11,
}

class NotificationsEntity {
  createdAt: string = '';

  subject: string = '';

  content: string = '';

  seenStatus?: number;

  args?: Array<{ key: string; value: string }>;

  group?: number;

  id: string = '';

  textStyle?: number;

  eventType?: eventType;

  refId?: string;

  constructor(notifications: Partial<NotificationsEntity>) {
    if (!notifications) {
      return;
    }
    Object.assign(this, notifications);
  }

  static createListNotifications(listNotifications: Array<Partial<NotificationsEntity>>) {
    if (!Array.isArray(listNotifications)) {
      return [];
    }
    return listNotifications.map((notifications: Partial<NotificationsEntity>) => {
      return new NotificationsEntity(notifications);
    });
  }
}
export default NotificationsEntity;
