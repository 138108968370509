export default {
  'organizations.router.name': 'Account',
  'organizations.router.nameBreadcrumb': 'Account Management',
  'organizations.title': 'Account List',
  'organizations.summary.totalOrganization': 'Total Account',
  'organizations.summary.totalFacility': 'Total Locations',
  'organizations.filter.operationStatus': 'Status',
  'organizations.filter.operationStatus.unexpired': 'Active',
  'organizations.filter.operationStatus.expire': 'Not Active',
  'organizations.filter.operationStatus.cancelOrganization': 'Cancelled',
  'organizations.filter.operationStatus.notActivated': 'Upcoming',
  'organizations.filter.effectiveTime': 'Valid From',
  'organizations.filter.expiredTime': 'Valid To',
  'organizations.table.code': 'Account Identification Code',
  'organizations.table.name': 'Account Name',
  'organizations.table.numberOfFacility': 'Total No Locations',
  'organizations.table.capacityUsed': 'Renewal Package',
  'organizations.table.tariffPackage.periodByMonth': 'Duration',
  'organizations.table.tariffPackage.effectiveTime': 'Valid From',
  'organizations.table.tariffPackage.expiredTime': 'Valid To',
  'organizations.table.tariffPackage.numberOfDaysRemaining': 'Total expiration day',
  'organizations.table.operationStatus': 'Status',
  'organizations.table.operationStatus.params':
    '{status,select, 1 {Active} 2 {Not Active} 3 {Canceled} 4 {Upcoming} other {Undefined}}',
  'organizations.confirmDelete.title': 'Confirmation of Account Deletion',
  'organizations.confirmDelete.content':
    'Please confirm and this account will be deleted and can not be restored.',
  'organizations.action.menu': 'Location Management',
  'organizations.action.file.expired': 'Discontinued Subscription',
  'organizations.action.file.title': 'Subscription Extension Confirmation',
  'organizations.action.file.expired.content':
    'Cannot renew because the subscription {tariffPackagesName} has been {text}. Please choose a different subscription to use.',
  'organizations.action.file.expired.text': 'Discontinued',
  'organizations.action.file.content':
    'This subscription will be renewed from {dateForm} to {dateTo}',
  'organizations.action.filePlus': 'Renew subscription',
  'organizations.action.close': 'Cancel Account ',
  'organizations.action.x.title': 'Confirmation of Account Cancellation',
  'organizations.action.x.content':
    'The subscription {packageName} will be canceled and data will be deleted due to account cancellation.',
  'organizations.modal.create.title': 'Add Account',
  'organizations.modal.create.title.infoData': 'Subscription Information',
  'organizations.modal.create.title.infoOrganizations': 'Account Information',
  'organizations.modal.create.title.infoAccount': 'Login Information',
  'organizations.modal.create.form.code': 'Account Identification Code',
  'organizations.modal.create.form.code.required': 'Invalid account identifier code format',
  'organizations.modal.create.form.code.placeholder': 'Please enter the account identifier code',
  'organizations.modal.create.form.name': 'Account Name',
  'organizations.modal.create.form.name.placeholder': 'Please enter the account name',
  'organizations.modal.create.checkbox.data': 'Available Subscription',
  'organizations.modal.create.checkbox.notData': 'Other Subscription',
  'organizations.modal.create.tariffPackagesSelect': 'Storage Subscription',
  'organizations.modal.create.tariffPackagesSelect.placeholder': 'Please select a subscription',
  'organizations.modal.create.capacity': 'Storage Capacity',
  'organizations.modal.create.capacity.placeholder': 'Please enter the capacity',
  'organizations.modal.create.periodByMonth': 'Duration',
  'organizations.modal.create.periodByMonth.month': 'By Month',
  'organizations.modal.create.periodByMonth.unlimited': 'Unlimited',
  'organizations.modal.create.periodByMonth.placeholder': 'Please enter the duration',
  'organizations.modal.create.effectiveTime': 'Valid From',
  'organizations.modal.create.expiredTime': 'Valid To',
  'organizations.modal.create.price': 'Price',
  'organizations.modal.create.price.placeholder': 'Please enter the price',
  'organizations.modal.create.account.name': 'Full Name',
  'organizations.modal.create.account.name.placeholder': 'Please enter the full name',
  'organizations.modal.create.account.email': 'Email',
  'organizations.modal.create.account.email.required': 'Invalid email format',
  'organizations.modal.create.account.email.placeholder': 'Please enter the email',
  'organizations.modal.create.account.timeZone': 'Timezone',
  'organizations.modal.create.account.timeZone.placeholder': 'Please select a timezone',
  'organizations.modal.create.account.phoneNumber': 'Phone Number',
  'organizations.modal.create.account.phoneNumber.placeholder': 'Please enter the phone number',
  'organizations.modal.create.account.dayOfBirth': 'Date of Birth',
  'organizations.modal.create.account.dayOfBirth.placeholder': 'Please enter the date of birth',
  'organizations.modal.create.account.username': 'Login Username',
  'organizations.modal.create.account.username.placeholder': 'Please enter the login username',
  'organizations.modal.create.account.password': 'Password',
  'organizations.modal.create.account.password.placeholder': 'Please enter the password',
  'organizations.modal.create.account.password.required':
    'The password must be at least 8 characters long and include lowercase letters, uppercase letters, numbers, and special characters.',
  'organizations.router.info.name': 'Account Information',
  'organizations.info.title': 'Account Information',
  'organizations.info.title.infoOrganizations': 'Account Information',
  'organizations.info.code': ' Account Identification Code',
  'organizations.info.name': 'Account Name',
  'organizations.info.operationStatus': 'Status',
  'organizations.info.operationStatus.params':
    '{status,select, 1 {Active} 2 {Not Active} 3 {Canceled Account } 4 {Upcoming} other {Undefined}}',
  'organizations.info.capacity': 'Storage Used',
  'organizations.info.title.infoAccount': 'Login Information',
  'organizations.info.account.name': 'Full Name',
  'organizations.info.account.username': 'Login Username',
  'organizations.info.account.email': 'Email',
  'organizations.info.account.phoneNumber': 'Phone Number',
  'organizations.info.account.dayOfBirth': 'Date of Birth',
  'organizations.info.account.timeZone': 'Timezone',
  'organizations.info.title.packageInfo': 'Subscription Information',
  'organizations.info.title.history.transaction': 'Transaction History',
  'organizations.info.filter.extensionTime': 'Renewal Period',
  'organizations.paymentHistories.table.gracetime': 'Renewal Period',
  'organizations.paymentHistories.table.tariffPackage.capacity': 'Storage Capacity',
  'organizations.paymentHistories.table.organization.tariffPackage.effectiveTime':
    'Validity Period',
  'organizations.paymentHistories.table.effectiveTime': 'Validity Period',
  'organizations.paymentHistories.table.price': 'Subscription Fee',
  'organizations.paymentHistories.table.payer.name': 'Payer',
  'organizations.paymentHistories.table.payer.phoneNumber': 'Phone Number',
  'organizations.paymentHistories.table.paymentStatus': 'Status',
  'organizations.paymentHistories.table.paymentMethod': 'Method',
  'organizations.paymentHistories.table.paymentStatus.params':
    '{status,select, 1 {Succeeded} 2 {Processing} 3 {Failed} 4 {Canceled} other {Other}}',
  'organizations.paymentHistories.table.paymentMethod.params':
    '{status,select, 1 {Direct} 2 {E-wallet} 3 {VNPay} 4 {--} 5 {PayPal} 6 {OnePay} other {Other}}',
  'organizations.info.action.x.title': 'Confirm Cancellation of Subscription Change',
  'organizations.info.action.x.content': 'The subscription {packageName}/{month} will be canceled.',
  'organizations.info.businessStatus.outOfBusiness': 'Discontinued',
  'organizations.info.paymentStatus.processing': 'Processing',
  'organizations.info.modal.change.add.title': 'Add Subscription',
  'organizations.info.modal.change.update.title': 'Change Subscription',
  'organizations.info.modal.change.checkbox.data': 'Available Subscription',
  'organizations.info.modal.change.checkbox.notData': 'Other Subscription',
  'organizations.info.modal.change.tariffPackagesSelect': 'Storage Subscription',
  'organizations.info.modal.change.tariffPackagesSelect.placeholder':
    'Please select a subscription',
  'organizations.info.modal.change.capacity': 'Capacity',
  'organizations.info.modal.change.capacity.placeholder': 'Please enter capacity',
  'organizations.info.modal.change.account.periodByMonth': 'Duration',
  'organizations.info.modal.change.account.periodByMonth.month': 'By Month',
  'organizations.info.modal.change.account.periodByMonth.unlimited': 'Unlimited',
  'organizations.info.modal.change.account.periodByMonth.placeholder': 'Please enter the duration',
  'organizations.info.modal.change.account.effectiveTime': 'Valid From',
  'organizations.info.modal.change.account.expiredTime': 'Valid To',
  'organizations.info.modal.change.account.price': 'Price',
  'organizations.info.modal.change.account.price.placeholder': 'Please enter the price',
  'organizations.info.modal.change.render.total': 'Total Amount {total}',
  'organizations.info.modal.update.title': 'Edit Account',
  'organizations.info.modal.update.title.infoOrganizations': 'Account Information',
  'organizations.info.modal.update.form.code': 'Account Identification Code',
  'organizations.info.modal.update.form.code.required':
    'Invalid account identification code format',
  'organizations.info.modal.update.form.code.placeholder':
    'Please enter the account identification code',
  'organizations.info.modal.update.form.name': 'Account Name',
  'organizations.info.modal.update.form.name.placeholder': 'Please enter the account Name',
  'organizations.info.modal.update.form.isAutoRenewal': 'Automatic Renewal',
  'organizations.info.modal.update.form.isAutoRenewal.yes': 'Agree',
  'organizations.info.modal.update.form.isAutoRenewal.no': 'Cancel',
  'organizations.info.modal.update.title.infoAccount': 'Login Information',
  'organizations.info.modal.update.account.name': 'Full Name',
  'organizations.info.modal.update.account.name.placeholder': 'Please enter full name',
  'organizations.info.modal.update.account.email': 'Email',
  'organizations.info.modal.update.account.email.placeholder': 'Please enter email',
  'organizations.info.modal.update.account.timeZone': 'Timezone',
  'organizations.info.modal.update.account.timeZone.placeholder': 'Please select a timezone',
  'organizations.info.modal.update.account.phoneNumber': 'Phone Number',
  'organizations.info.modal.update.account.phoneNumber.placeholder':
    'Please enter the phone number',
  'organizations.info.modal.update.account.dayOfBirth': 'Date of Birth',
  'organizations.info.modal.update.account.dayOfBirth.placeholder':
    'Please enter the date of birth',
  'organizations.info.modal.update.account.username': 'Login Username',
  'organizations.info.modal.update.account.username.placeholder': 'Please enter the login username',
};
