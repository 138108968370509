import 'dayjs/locale/en';

import enUS from 'antd/lib/locale/en_US';

import announcements from './announcements';
import common from './common';
import dashboard from './dashboard';
import device from './device';
import facility from './facility';
import newsManagement from './newsManagement';
import notifications from './notifications';
import organizations from './organizations';
import pageError from './pageError';
import pagePaymentSuccess from './pagePaymentSuccess';
import profile from './profile';
import server from './server';
import settingSystem from './settingSystem';
import sourceInformation from './sourceInformation';
import stepView from './stepView';
import tariff from './tariff';

export default {
  ...enUS,
  ...stepView,
  ...common,
  ...dashboard,
  ...pagePaymentSuccess,
  ...server,
  ...newsManagement,
  ...profile,
  ...pageError,
  ...device,
  ...tariff,
  ...organizations,
  ...facility,
  ...sourceInformation,
  ...settingSystem,
  ...announcements,
  ...notifications,
};
