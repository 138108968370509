import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'src/core/store/redux';

import CONFIG from '@config';
import { removeProfile } from '@modules/authentication/profileStore';
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Loading from '@shared/components/Loading';

import Cookies from 'js-cookie';
import App from './App';

interface IPersistStore {
  remember?: boolean;
}

const PersistStore: React.FC<IPersistStore> = ({ remember }) => {
  const handleBeforeLift = () => {
    const handleRemoveProfile = () => {
      store.dispatch(removeProfile());
      Cookies.remove('accessTokenMtc');
      Cookies.remove('usernameMtc');
    };
    return new Promise<void>(resolve => {
      const timer = setTimeout(() => {
        store.dispatch(removeProfile());
        resolve();
      }, 3000);
      const accessToken = Cookies.get('accessTokenMtc');
      const username = Cookies.get('usernameMtc');
      if (accessToken != null && accessToken !== '') {
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.src = CONFIG.LOGIN_PAGE;
        document.body.appendChild(iframe);
        const data = {
          message: 'checkLogin',
          data: {
            username,
          },
        };
        iframe.onload = () => {
          iframe.contentWindow?.postMessage(data, '*');
        };
      } else {
        resolve();
        clearTimeout(timer);
        handleRemoveProfile();
      }
      window.addEventListener('message', e => {
        const data = e.data;
        if (data.message === 'recheckLogin') {
          if (data.data === 'success') {
            resolve();
            clearTimeout(timer);
          } else if (data.data === 'fail') {
            resolve();
            handleRemoveProfile();
            clearTimeout(timer);
          }
        }
      });
    });
  };
  return (
    <PersistGate loading={<Loading />} persistor={persistor} onBeforeLift={handleBeforeLift}>
      <App />
    </PersistGate>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <Router>
    <Provider store={store}>
      <PersistStore remember={true} />
    </Provider>
  </Router>,
);
