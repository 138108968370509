import React from 'react';

import { IRouter } from '@routers/interface';

export const routerDevicesInfo: IRouter = {
  path: '/devices/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.router.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(id: string, listPermissions: string[]) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/devices/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}`;
    }
    return `/devices/info/${id}`;
  },
};

export const routerOrganizationDeviceInfo: IRouter = {
  path: '/organization/:organizationId/devices/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'device.router.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(organizationId, id: string, listPermissions: string[]) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/organization/${organizationId}/devices/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}`;
    }
    return `/organization/${organizationId}/devices/info/${id}`;
  },
};
