import React from 'react';

import { IRouter } from '@routers/interface';

export const routerPlaylistInfo: IRouter = {
  path: '/source-information/playlist/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(id: string, listPermissions: string[]) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/source-information/playlist/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}`;
    }
    return `/source-information/playlist/info/${id}`;
  },
};

export const routerOrganizationPlaylistInfo: IRouter = {
  path: '/organization/:organizationId/source-information/playlist/info/:id',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(id: string, organizationId: string, listPermissions: string[]) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/organization/${organizationId}/source-information/playlist/info/${id}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}`;
    }
    return `/organization/${organizationId}/source-information/playlist/info/${id}`;
  },
};

export const routerPlaylistInfoFacility: IRouter = {
  path: '/source-information/playlist/info/:id/:facilityId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(id: string, listPermissions: string[], facilityId?: string) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/source-information/playlist/info/${id}?tab=${permissionTabs.find(item =>
        listPermissions.includes(item),
      )}/${facilityId}`;
    }
    return `/source-information/playlist/info/${id}/${facilityId}`;
  },
};

export const routerOrganizationPlaylistInfoFacility: IRouter = {
  path: '/organization/:organizationId/source-information/playlist/info/:id/:facilityId',
  loader: React.lazy(() => import('./index')),
  exact: true,
  name: 'sourceInformation.router.playlist.info.name', //translate here for breadcrumb and sidebar
  masterLayout: true,
  generatePath(id: string, organizationId: string, listPermissions: string[], facilityId?: string) {
    // const permissionTabs = [PermissionEnum.RankingGiftsView, PermissionEnum.OtherGiftsView];
    const permissionTabs = ['tab1', 'tab2', 'tab3'];
    if (permissionTabs.find(item => listPermissions?.includes(item))) {
      return `/organization/${organizationId}/source-information/playlist/info/${id}?tab=${permissionTabs.find(
        item => listPermissions.includes(item),
      )}/${facilityId}`;
    }
    return `/organization/${organizationId}/source-information/playlist/info/${id}//${facilityId}`;
  },
};
